import React, {useEffect, useState} from 'react';
import {ThemeConfig} from "antd/lib";
import ru_RU from 'antd/lib/locale/ru_RU';
import {
    Button,
    Col,
    ConfigProvider,
    Divider,
    Drawer,
    Flex,
    Row,
     SelectProps,
    Space,
    DatePicker as AntDatePicker, 
} from "antd";

import {Colors, Radio, DatePicker, Select, Input, Checkbox, InputNumber,  Form, Typography} from "@gp-frontend-ui/ui-kit-5v2";
import { ArrowLeftOutlined, BuildOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import styled from "styled-components";

const {RangePicker} = AntDatePicker;

// const { RangePicker } = DatePicker;

const StyledDrawer = styled(Drawer)`
    .ant-drawer-header {
        position: relative;

        .ant-drawer-header-title {
            flex-direction: row-reverse;
            height: 24px;

            .ant-drawer-close {
                margin-inline-end: 0;
            }
        }

        .ant-drawer-extra {
            position: absolute;
            right: 60px;
        }
    }
`;

const StyledFooterFlex = styled(Flex)`
    padding-top: 24px;
    background-color: ${Colors.white};
    border-top: 1px ${Colors.blue} solid;
`;

const conditionOptions = [
    {
        label: 'Равно',
        value: '1'
    },
    {
        label: 'Не равно',
        value: '2'
    },
    {
        label: 'Меньше',
        value: '3'
    },
    {
        label: 'Больше',
        value: '4'
    }
];

const selectOptions: SelectProps['options'] = [];

for (let i = 10; i < 36; i++) {
    selectOptions.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

const FilterPage = () => {
    const [theme, setTheme] = useState<ThemeConfig>({});

    const getThemeData = async () => {
        const response = await fetch(
            "theme.json"
        ).then((response) => response.json());

        // Обновим состояние
        setTheme(response);
    };

    useEffect(() => {
        getThemeData();
    }, []);


    return (<ConfigProvider theme={theme} locale={ru_RU}>
        <div className={"page-wrap"}>
            <Typography.TitleH2><span style={{color: Colors.cloudy.dark}}><ArrowLeftOutlined/></span> Test for
                ui-kit</Typography.TitleH2>
            <StyledDrawer
                title={"Фильтры"}
                width={1200}
                rootStyle={{left: '36px', top: '96px'}}
                closable={true}
                onClose={() => {
                }}
                open={true}
                placement={"left"}

            >
                <Button
                    onClick={() => {
                    }}
                    type="link"
                    icon
                    style={{
                        color: "orange",
                        fontWeight: "bold"
                    }}
                >
                    Свернуть расширенные фильтры
                    <DownOutlined />
                </Button>
                <Divider/>

                <Form initialValues={{}} layout="vertical">
                    <Row gutter={40}>
                        <Col span={6}>
                            <Space.Compact direction="vertical" block>
                                <Form.Item label="Текстовое поле" required>
                                    <Input  placeholder="Введите значение"/>
                                </Form.Item>
                                <Form.Item label="Текстовое поле (disabled)" required>
                                    <Input disabled  placeholder="Введите значение"/>
                                </Form.Item>
                                <Form.Item label="Текстовое поле (disabled)" required>
                                    <Input disabled  placeholder="Введите значение" value={'Filled disabled'}/>
                                </Form.Item>
                            </Space.Compact>
                        </Col>
                        <Col span={9}>
                            <Space.Compact direction="vertical" block>
                                <Form.Item label="Текстовое поле">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={16} push={1}>
                                            <Input  placeholder="Введите значение"/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Числовое поле">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={16} push={1}>
                                            <InputNumber placeholder="Введите значение"/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Сдвоенное числовое поле (с...по)">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={8} push={1}>
                                            <InputNumber placeholder="с..."/>
                                        </Col>
                                        <Col span={8}>
                                            <InputNumber  placeholder="по..."/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Дата">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={16} push={1}>
                                            <DatePicker placeholder="Выберите дату"/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Сдвоенная дата">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={7} push={1}>
                                            <DatePicker placeholder="Выберите дату"/>
                                        </Col>
                                        <Col span={7} push={2}>
                                            <DatePicker placeholder="Выберите дату"/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Диапазон дат">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={16} push={1}>
                                            <RangePicker />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Выбор (select)">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={16} push={1}>
                                            <Select options={selectOptions} allowClear placeholder="Выберите значение"/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item label="Множественный выбор (multi select)">
                                    <Row>
                                        <Col span={7}>
                                            <Select options={conditionOptions}/>
                                        </Col>
                                        <Col span={16} push={1}>
                                            <Select mode="multiple" options={selectOptions} allowClear placeholder="Выберите значение"/>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Space.Compact>
                        </Col>
                        <Col span={9}>
                            <Space.Compact direction="vertical" block>

                                <Form.Item label="Текстовое поле">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Текстовое поле 2">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Текстовое поле 3">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Текстовое поле 3" style={{marginLeft:300}}>
                                    <DatePicker placement={"bottomRight"}/>
                                </Form.Item>
                            </Space.Compact>
                        </Col>
                    </Row>
                </Form>

                <StyledFooterFlex justify="space-between">
                    <div style={{display: "flex", gap: 7}}>
                        <Button type="primary">Найти</Button>
                        <Button>Сбросить</Button>
                    </div>
                    <Button>Сбросить расширенные фильтры</Button>
                </StyledFooterFlex>
            </StyledDrawer>
        </div>

    </ConfigProvider>);
};

export default FilterPage;
