import styled from "styled-components";
import { Colors, Input, InputProps } from '@gp-frontend-ui/ui-kit-5v2';

export const StyledInput = styled(Input)<InputProps>`
	&.ant-input-number-group-wrapper {
		
		.ant-input-number-wrapper {
			border: 1px solid ${Colors.cloudy.main};
			border-radius: 6px;
			height: 38px;
			background-color: ${Colors.white};

			&.ant-input-number-group-addon {
				font-size: 8px;
				border: none;
				background-color: ${Colors.white};
			}

			.ant-input-number {
				vertical-align: middle;
				input {
					font-size: 14px;
					text-align: center;
					height: 100%;
				}
			}
		}
	}
`;

