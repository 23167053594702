import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "@gp-frontend-ui/ui-kit-5v2/dist/theme/montserrat.css";

import {BrowserRouter} from "react-router-dom";
import {Pages} from "./routes";
import {ConfigProvider} from "antd";
import ru_RU from 'antd/lib/locale/ru_RU';
import {theme} from "@gp-frontend-ui/ui-kit-5v2";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ConfigProvider theme={theme} locale={ru_RU}>
        <BrowserRouter>
            <Pages/>
        </BrowserRouter>
    </ConfigProvider>
);
