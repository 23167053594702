import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    ConfigProvider,
    Row,
    Space,
    Input as AntInput,
    Card
} from 'antd';
import { ArrowLeftOutlined, BuildOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {createStyles, useTheme} from 'antd-style';
import {ThemeConfig} from "antd/lib";
import dayjs from 'dayjs';
import {
    Colors,
    Input,
    Typography,
    Radio,
    InputNumber,
    Checkbox,
    Select,
    Collapse,
    DatePicker,
    RangePicker,
    Form,
} from "@gp-frontend-ui/ui-kit-5v2";
import { displayDateTime } from '../funcs';
//import {Colors} from '../colors';

const { TextArea } = AntInput;

const CardEdit = () => {
    const [theme, setTheme] = useState<ThemeConfig>({});
    useEffect(() => { 
        const getThemeData = async () => {
            const response = await fetch( "theme.json" ).then((response) => response.json());
            // Обновим состояние
            setTheme(response);
        };
        getThemeData(); 
    }, []);

    return (<ConfigProvider theme={theme}>
        <div className={"page-wrap"}>
            <Row>
                <Col flex="1">
                    <Typography.TitleH2>
                        <span style={{color:Colors.cloudy.dark}}><ArrowLeftOutlined /></span> Форма №4.1-ГП Статистическая карточка на лицо, совершившее преступление (длинный заголовок в 2 или более строк)
                    </Typography.TitleH2>
                </Col>
                <Col flex='0'>
                    <Button block={false} type='default' size='middle'>Опубликовать</Button>
                </Col>
            </Row>

            <Form 
                layout='inline'
                labelCol={{ flex: "300px" }}
                wrapperCol={{ flex: "1 0 100%" }}
                labelWrap={true}
                labelAlign='left'
                colon={false}
                size='large'
            >

                <Space direction='vertical' size={[20,20]}>
                    <Form.Item label='1.1. Признак карточки' name='r1.1'>
                        <Radio.Group direction="horizontal" options={[{label:'Типовая', value:1}, {label:'Закрытая', value:1}, {label:'Открытая', value:1}]}></Radio.Group>
                    </Form.Item>

                    <Form.Item required label='1.2. Тип карточки' name='r1.2'>
                        <Input defaultValue='Исключено из официальной статистической информации на основании решения суда' />
                    </Form.Item>

                    <Form.Item label='2.1. Подразделение, в производстве которого находится уголовное дело (разрешен материал)' name='r2.1'>
                        <Input defaultValue='Отдел полиции «Карабаш» ОМВД России по Бугульминскому району Республики Татарстан' />
                    </Form.Item>

                    <Form.Item label='2.2. Структурное подразделение органа федеральной службы безопасности' name='r2.2'>
                        <Input placeholder='Введите наименование подразделения' />
                    </Form.Item>

                    <Collapse items={[
                        {
                            key: '1',
                            label: 'Сведения об уголовном деле / постановлении об отказе в возбуждении уголовного дела',
                            children: <Group1 />,
                        },
                        {
                            key: '2',
                            label: 'Сведения о лице, подозреваемом, обвиняемом в совершении преступления',
                            children: <Group2 />,
                        },
                        {
                            key: '3',
                            label: 'Группа, характеризующая пребывание иностранных граждан и лиц без гражданства в Российской Федерации (может быть многострочным)',
                            children: <Group3 />,
                            className: 'multi',
                        },
                        {
                            key: '4',
                            label: 'Сведения о дополнительных характеристиках лица на момент совершения преступления',
                            children: <Group4 />,
                        },
                    ]}
                    />
                </Space>
            </Form>

        </div>
    </ConfigProvider>);
};

export default CardEdit;

const Group1 = () => 
        <Space direction='vertical'>
            <Form.Item label='3.1. Уголовное дело, постановление об отказе в возбуждении уголовного дела' name='r3.1'>
                <Input placeholder='Уголовное дело' />
            </Form.Item>
            <Form.Item label='3.2. Дата вынесения постановления о возбуждении (отказе в возбуждении) уголовного дела' name='r3.2'>
                <DatePicker placeholder='09.08.2024' />
            </Form.Item>
            <Form.Item label='3.4. Идентификационный код уголовного дела'>
                <Row gutter={8}>
                    <Col>
                        <Form.Item noStyle name='r3.4.1' help='Тип'>
                            <InputNumber disabled defaultValue={1} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item noStyle name='r3.4.2'>
                            <InputNumber defaultValue={23} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item noStyle name='r3.4.3'>
                            <InputNumber defaultValue={'01920097'} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item noStyle name='r3.4.4'>
                            <InputNumber defaultValue={'000678'} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button type='text' icon={<DeleteOutlined />}>Очистить</Button>
                    </Col>
                </Row>
            </Form.Item>
        </Space>;

const Group2 = () => 
    <Space direction='vertical'>
        <Form.Item label='4. Уникальный номер лица'>
            <Row gutter={8}>
                <Col>
                    <Form.Item noStyle name='r3.4.1' help='Тип'>
                        <InputNumber disabled defaultValue={1} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item noStyle name='r3.4.2' help='Год'>
                        <InputNumber defaultValue={23} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item noStyle name='r3.4.3' help='Код подразделения'>
                        <InputNumber defaultValue={'01920097'} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item noStyle name='r3.4.4' help='Номер УД'>
                        <InputNumber defaultValue={'000678'} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item noStyle name='r3.4.4' help='Тип лица'>
                        <InputNumber defaultValue={'2'} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item noStyle name='r3.4.4' help='Порядковый номер лица'>
                        <InputNumber defaultValue={'1'} />
                    </Form.Item>
                </Col>
                <Col>
                    <Button type='text' icon={<DeleteOutlined />}>Очистить</Button>
                </Col>
            </Row>
        </Form.Item>


        <Form.Item label='5.2. Наиболее тяжкое преступление' name='r5.2.1'>
            <Select  options={[{label: 'Да', value:1}, {label: 'Нет', value:2}, {label: 'Не знаю', value:3}]} />
        </Form.Item>

        <Form.Item label='5.2bis. Наиболее тяжкое преступление ( вариант 2)' name='r5.2.2'>
            <Checkbox />
        </Form.Item>

        <Form.Item label='6. Квалификация преступления'>
            <Row gutter={[8,24]} wrap>
                <Col span={12}>Действующая редакция УК РФ</Col>
                <Col span={12}>Не действующая редакция УК РФ</Col>
                <Col span={24}><Select mode='tags' options={[{label: 'ст.232 ч.1 в ред. 54-ФЗ от 05.06.2012', value:1}, {label: 'ст.232 ч.2 в ред. 54-ФЗ от 05.06.2012', value:2}, {label: 'ст.232 ч.3 в ред. 54-ФЗ от 05.06.2012', value:3}]} /></Col>
            </Row>
        </Form.Item>
        
        <Form.Item label='7. Фамилия' name='r7'>
            <TextArea rows={1} placeholder='Введите фамилию'></TextArea>
        </Form.Item>

        <Form.Item label='11.  Дата рождения' name='r11'>
            <DatePicker placeholder='Введите дату' />
        </Form.Item>

        <Form.Item label='14.1. Гражданство' name='r14.1'>
            <Select mode='tags' maxTagCount={1} options={[{label: 'Страна', value:1}, {label: 'Республика', value:2}, {label: 'Край', value:3}]} />
        </Form.Item>

    </Space>;

const Group3 = () => <Form.List name="items">
{(fields, { add, remove }) => (
  <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
    {fields.map((field) => (
        <Card
            size="small"
            title={`Запись №${field.name + 1}`}
            key={field.key}
            extra={
            <CloseOutlined
                onClick={() => {
                remove(field.name);
                }}
            />
            }
        >
            <Form.Item label="18.1. Цель въезда в Российскую Федерацию" name={[field.name, 'r18.1']}>
                <Select placeholder='Выберите цель' options={[{label: 'цель Да', value:1}, {label: 'цель Нет', value:2}, {label: 'Не знаю', value:3}]} />
            </Form.Item>
            <Form.Item label="18.2. Режим пребывания иностранного гражданина и лица без гражданства" name={[field.name, 'r18.2']}>
                <Select placeholder='Выберите режим' options={[{label: 'режим Да', value:1}, {label: 'режим Нет', value:2}, {label: 'Не знаю', value:3}]} />
            </Form.Item>
        </Card>
    ))}

    <Button type="link" onClick={() => add()} block>
      + Добавить запись
    </Button>
  </div>
)}
</Form.List>;

const Group4 = () => 
    <Space direction='vertical'>
        <Form.Item label='26. Преступление совершено в состоянии опьянения' name='r26'>
            <Select mode='multiple' options={[{label: 'Да', value:1}, {label: 'Нет', value:2}, {label: 'Не знаю', value:3}]} />
        </Form.Item>

        <Form.Item label='5.2bis. Наиболее тяжкое преступление ( вариант 2)' name='r5.2.2'>
            <Checkbox />
        </Form.Item>

        <Form.Item label='6. Квалификация преступления'>
            <Row gutter={[8,24]} wrap>
                <Col span={12}>Действующая редакция УК РФ</Col>
                <Col span={12}>Не действующая редакция УК РФ</Col>
                <Col span={24}><Select mode='tags' options={[{label: 'ст.232 ч.1 в ред. 54-ФЗ от 05.06.2012', value:1}, {label: 'ст.232 ч.2 в ред. 54-ФЗ от 05.06.2012', value:2}, {label: 'ст.232 ч.3 в ред. 54-ФЗ от 05.06.2012', value:3}]} /></Col>
            </Row>
        </Form.Item>
        
        <Form.Item label='7. Фамилия' name='r7'>
            <TextArea rows={1} placeholder='Введите фамилию'></TextArea>
        </Form.Item>

        <Form.Item label='11.  Дата рождения' name='r11'>
            <DatePicker placeholder='Введите дату' />
        </Form.Item>

        <Form.Item label='26. Преступление совершено в состоянии опьянения' name='r26'>
            <Select mode='tags' maxTagCount={1} options={[
                {label: 'алкогольного опьянения', value:1}, 
                {label: 'наркотического опьянения', value:2},
                {label: 'невыполнение законного требования уполномоченного должностного лица о прохождении', value:3},
                {label: 'медицинского освидетельствования на состояние опьянения', value:4},
                {label: 'токсического опьянения', value:5},
                {label: 'трезвости', value:6},
            ]} />
        </Form.Item>

        <Form.Item label='38. Лицо имеет непогашенную судимость' name='r38'>
            <Select 
                mode='tags' 
                maxTagCount={1} 
                showSearch
                options={[
                    {label: 'аналогичное преступление', value:1}, 
                    {label: 'хищение', value:2},
                    {label: 'вымогательство', value:3},
                    {label: 'незаконное предпринимательство', value:4},
                    {label: 'приобретение имущества, заведомо добытого преступным путем', value:5},
                    {label: 'сбыт имущества, заведомо добытого преступным путем', value:6},
                ]} 
            />
        </Form.Item>

        <Form.Item label='XX. Поиск' name='rXX'>
            <Input />
        </Form.Item>

        <Form.Item label='YY. Диапазон дат' name='rYY' help="placeholder='9th menu item">
            <RangePicker />
        </Form.Item>

        <div>** не ждите после ИКС и ИГРЕК третью букву</div>
    </Space>;
