import styled from "styled-components";
import { Tabs, TabsProps } from 'antd';
import { Colors, } from '@gp-frontend-ui/ui-kit-5v2';

export const StyledTabs = styled(Tabs)<TabsProps>`
	.ant-tabs-nav {
		&::before {
		    width: calc(100% - 378px);
    		border-bottom: 1px solid ${Colors.cloudy.main};
		}

		/* .ant-tabs-extra-content {
			background-color: ${Colors.white};
		    align-self: stretch;
    		display: inline-flex;
		} */
	}
`;

