import {Input, Typography} from "@gp-frontend-ui/ui-kit-5v2";

function App() {

    return (
       <>
           <Typography.TitleH2>Проверка работы ui-kit-5v2</Typography.TitleH2>
           <Input/>
       </>
    );
}

export default App;
