import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface DataType {
	key: number;
	name: string;
	age: number;
	address: string;
	description: string;
  }
  
  const columns: ColumnsType<DataType> = [
	{
	  title: 'Name',
	  dataIndex: 'name',
	},
	{
	  title: 'Age',
	  dataIndex: 'age',
	  sorter: (a, b) => a.age - b.age,
	},
	{
	  title: 'Address',
	  dataIndex: 'address',
	  width: 150,
	  filters: [
		{
		  text: 'London',
		  value: 'London',
		},
		{
		  text: 'New York',
		  value: 'New York',
		},
	  ],
	},
	{
	  title: 'Action',
	  key: 'action',
	  sorter: true,
	},
  ];

  const data = Array.from({ length: 10 }).map<DataType>((_, i) => ({
	key: i,
	name: 'John Brown',
	age: Number(`${i}2`),
	address: `New York No. ${i} Lake Park My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park. My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
	description: `My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
  }));

export const DemoTable = () => {
return <Table<DataType>
	columns={columns}
	dataSource={data}
/>;
};