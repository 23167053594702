import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    ConfigProvider,
    Row,
    Space,
    Upload,
    Collapse,
    Popover,
    Alert,
    Layout,
    Flex
} from 'antd';

import { ArrowLeftOutlined, BlockOutlined, BuildOutlined, DeleteOutlined, DownloadOutlined, FunctionOutlined, GroupOutlined, InfoCircleOutlined, InfoOutlined, MinusOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {createStyles, useTheme} from 'antd-style';
import {ThemeConfig} from "antd/lib";
import dayjs from 'dayjs';
import {
    Colors,
    Input,
    InputNumber,
    Typography,
    Select,
    Modal,
    DatePicker,
    Form,
    Tabs
} from "@gp-frontend-ui/ui-kit-5v2";

import { displayDateTime } from '../funcs';
//import {Colors} from '../colors';
import StickyBox from 'react-sticky-box';
import { StyledInput } from './inputNumber';
import { DemoTable } from './table';
import { StyledTabs } from './styledTabs';
import TextArea from 'antd/es/input/TextArea';
import { range } from 'lodash';
import Paragraph from 'antd/es/typography/Paragraph';

const FormEdit = () => {
    const [theme, setTheme] = useState<ThemeConfig>({});


    const getThemeData = async () => {
        const response = await fetch(
            "theme.json"
        ).then((response) => response.json());

        // Обновим состояние
        setTheme(response);
    };

    useEffect(() => {
        getThemeData();
    }, []);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState<false | 'footer' | 'centered' | 'styled'>(false);

    const modalHandleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const modalHandleCancel = () => {
        setOpen(false);
    };

    
    const [openi, setOpeni] = useState(false);
    const hide = () => {
        setOpeni(false);
    };

    const handleOpenChange = (newOpen: boolean) => {
        setOpeni(newOpen);
    };

    
    return (<ConfigProvider theme={theme}>
        <div className={"page-wrap"}>
            <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex:10,backgroundColor:Colors.white }}>
                <Row align='middle'>
                    <Col flex='auto'>
                        <Typography.TitleH1>
                            <span style={{color:Colors.cloudy.dark}}><ArrowLeftOutlined /></span> Редактирование формы ОН (код: 511)
                        </Typography.TitleH1>
                    </Col>
                    <Col >
                        <Space size={10} align='baseline'>
                            Период действия
                            <Select defaultValue={4}>
                                <Select.Option value={1}>01.01.2021 — 31.12.2022</Select.Option>
                                <Select.Option value={2}>01.01.2022 — 31.12.2023</Select.Option>
                                <Select.Option value={3}>01.01.2023 — 31.12.2024</Select.Option>
                                <Select.Option value={4}>01.01.2024 — 31.12.2025</Select.Option>
                            </Select>
                            <Space size={0} align='baseline'>
                                <span style={{color:Colors.cloudy.main}}><Button color="primary" variant="text" size='middle' icon={<BlockOutlined />} onClick={() => setOpen('centered')} /></span>
                                <span style={{color:Colors.cloudy.main}}><Button color="primary" variant="text" size='middle' icon={<DeleteOutlined />} onClick={() => setOpen('centered')} /></span>

                                <span style={{color:Colors.blue.light}}>
                                    <Popover
                                        content={<ReportInfo status={false} />}
                                        trigger="click"
                                        open={openi}
                                        onOpenChange={handleOpenChange}
                                    >
                                        <Button color="primary" variant="text" size='middle' icon={<InfoCircleOutlined />} />
                                    </Popover>
                                </span>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </StickyBox>

            <Layout style={{minHeight:'calc(100vh - 96px)'}}>
                <Layout.Content style={{minHeight: 'calc(100% - 96px - 48px)', backgroundColor: Colors.white}}>
                    <Tabs 
                        items={[
                            {
                                key: '1',
                                label: 'Base',
                                children: <BasePane />,
                            },
                            {
                                key: '2',
                                label: 'Бланк',
                                children: <BlankPane />,
                            },
                            {
                                key: '3',
                                label: 'Настройка разделов',
                                children: <DimsPane />,
                            },
                            {
                                key: '4',
                                label: 'Раздел 1',
                                children: <PartsPane />,
                            },
                            {
                                key: '5',
                                label: 'Раздел 2',
                                children: <PartsPane />,
                            },
                            {
                                key: '6',
                                label: 'Раздел 3',
                                children: <PartsPane />,
                            },

                        ]}
                        defaultActiveKey="1"
                        // renderTabBar={(props, DefaultTabBar) => (
                        //     <StickyBox offsetTop={48} offsetBottom={20} style={{ zIndex: 5 }}>
                        //       <DefaultTabBar {...props} style={{ background: Colors.white }} />
                        //     </StickyBox>
                        // )}
                        
                    />
                </Layout.Content>
                <Layout.Footer style={{backgroundColor: Colors.white}}>
                    <Flex gap={16} justify='flex-end'>
                        <Button block={false} type='default' >Сохранить</Button>
                        <Button block={false} type='primary' >Опубликовать форму</Button>
                    </Flex>
                </Layout.Footer>
            </Layout>
        </div>
        <Modal
            open={!!open}
            title="Удаление формы отчетности"
            onOk={modalHandleOk}
            onCancel={modalHandleCancel}
            footer={[
                <Button key="back" type="primary" onClick={modalHandleCancel}>
                    Удалить
                </Button>,
                <Button key="submit" type="default" loading={loading} onClick={modalHandleOk}>
                    Отменить
                </Button>,
            ]}
        >
            <p><b>Вы уверены, что хотите удалить форму отчетности целиком?</b></p>
            <p>У этой формы существует только один период действия.</p>
        </Modal>
    </ConfigProvider>);
};

//dayjs.extend(customParseFormat)

const initBase = {
    id: 352154646461321,
    name: '511',
    short: 'ОН',
    full: 'Надзор за исполнением законов, соблюдением прав и свобод человека и гражданина является одной из важнейших функций прокуратуры Российской Федерации. Это конституционно закрепленная роль прокуратуры, направленная на защиту законных интересов граждан, обеспечение верховенства закона',
    start: '04.09.2023',
    stop: '15.03.2024',
    prikaz_start: undefined,
    prikaz_stop: undefined,
    period: 1,
    transport: 1,
};

const dateFormat = 'DD.MM.YYYY';

const BasePane = () => {
    return (<div>
        <Form 
            initialValues={initBase}
            layout='inline'
            labelCol={{ flex: "300px" }}
            wrapperCol={{ flex: "1 0 100%" }}
            labelWrap={true}
            labelAlign='left'
            colon={false}
            size='large'
        >
            <Space direction='vertical' size={[20,20]}>
                <Form.Item label="Идентификатор формы" name='id'>
                    <Row gutter={[10,20]} style={{width:'100%'}}><Col span={24}>
                        <Input disabled />
                    </Col></Row>
                </Form.Item>

                <Form.Item required label='Наименование формы' labelAlign={"left"}>
                    <Row gutter={[10,20]} style={{width:'100%'}}>
                        <Col span={6} style={{width:'100%'}}>
                            <Form.Item name='name' help='Числовой код формы'  style={{width:'100%'}}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}  style={{width:'100%'}}>
                            <Form.Item name='short' help='Сокращенное наименование формы' style={{width:'100%'}} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='full' help='Полное наименование'  style={{width:'100%'}}>
                                <Input.Textarea rows={5}  style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item label='Даты начала и окончания эксплуатации версии'>
                    <Row gutter={[10,20]} style={{width:'100%'}}>
                        <Col span={12}>
                            <Form.Item required name='start_date'
                                getValueProps={(value) => ({ value: value && dayjs(value, "DD.MM.YYYY") })}
                                /* normalize={(value) => value && `${dayjs(value).valueOf()}`} */
                                style={{width:'100%'}}
                            >
                                <DatePicker 
                                    format={dateFormat} 
                                    picker="month" 
                                    inputReadOnly 
                                    minDate={dayjs().endOf('month').add(1, 'day')} 
                                    style={{width:'100%'}}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='stop_date' 
                                getValueProps={(value) => ({ value: value && dayjs(value, "DD.MM.YYYY") })}
                                /* normalize={(value) => value && `${dayjs(value).valueOf()}`} */
                                style={{width:'100%'}}
                            >
                                <DatePicker 
                                    format={dateFormat} 
                                    picker="month" 
                                    inputReadOnly 
                                    minDate={dayjs().endOf('month').add(1, 'day')} 
                                    style={{width:'100%'}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label='Сканы приказов'>
                    <Row>
                        <Col span={12}>
                            <Form.Item name='prikaz_start'>
                                <Upload name="logo" action="#" listType="picture">
                                    <Button variant='outlined' color='default' icon={<PlusOutlined />}>Добавить файл</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col flex='0 1'>
                            <Form.Item name='prikaz_stop'>
                                <Button variant='outlined' color='default' icon={<PlusOutlined />}>Добавить файл</Button>
                            </Form.Item>
                        </Col>
                        <Col flex='1 0'>
                            <Form.Item name='stop'>
                                <Button variant='outlined' color='danger'>Остановить действие</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item required label='Периодичность представления информации' name='period'>
                    <Row gutter={[10,20]} style={{width:'100%'}}><Col span={24}>
                        <Select>
                            <Select.Option value={1}>Ежемесячно</Select.Option>
                        </Select>
                    </Col></Row>
                </Form.Item>
                <Form.Item label='Способ представления информации' required name='transport'>
                    <Row gutter={[10,20]} style={{width:'100%'}}><Col span={24}>
                        <Select>
                            <Select.Option value={1}>Электронный</Select.Option>
                        </Select>
                    </Col></Row>
                </Form.Item>
                <Form.Item label='Направления деятельности' name='activity'>
                    <Row gutter={[10,20]} style={{width:'100%'}}><Col span={24}>
                        <Select mode="multiple" allowClear>
                            <Select.Option value={1}>Общий надзор</Select.Option>
                        </Select>
                    </Col></Row>
                </Form.Item>
            </Space>
        </Form>
    </div>);
};


const BlankPane = () => {
    return (<div>
        <StyledTabs 
            items={[
                {
                    key: '1',
                    label: 'Титул лист',
                    children: <ExcelPane />,
                },
                {
                    key: '2',
                    label: 'Раздел 1',
                    children: <ExcelPane />,
                },
                {
                    key: '3',
                    label: 'Раздел 2',
                    children: <ExcelPane />,
                },
                {
                    key: '4',
                    label: 'Раздел 3',
                    children: <ExcelPane />,
                },
                {
                    key: '5',
                    label: 'Раздел 4',
                    children: <ExcelPane />,
                },
                {
                    key: '6',
                    label: 'Раздел 5',
                    children: <ExcelPane />,
                },
                {
                    key: '7',
                    label: 'Раздел 6',
                    children: <ExcelPane />,
                },
                {
                    key: '8',
                    label: 'Раздел 7',
                    children: <ExcelPane />,
                },
                {
                    key: '9',
                    label: 'Раздел 8',
                    children: <ExcelPane />,
                },
                {
                    key: '10',
                    label: 'Раздел 9',
                    children: <ExcelPane />,
                },
                {
                    key: '11',
                    label: 'Раздел 10',
                    children: <ExcelPane />,
                },
                {
                    key: '12',
                    label: 'Раздел 11',
                    children: <ExcelPane />,
                },
                {
                    key: '13',
                    label: 'Раздел 12',
                    children: <ExcelPane />,
                },
                {
                    key: '14',
                    label: 'Раздел 13',
                    children: <ExcelPane />,
                },
            ]}
            defaultActiveKey="1"
            renderTabBar={(props, DefaultTabBar) => (
                <StickyBox offsetTop={90} offsetBottom={0} style={{ zIndex: 2 }}>
                  <DefaultTabBar {...props} style={{ background: Colors.white }} />
                </StickyBox>
            )}
            tabBarExtraContent={{right: <Space>
                <Button type="text" icon={<GroupOutlined />}></Button>
                <Button type="text" icon={<FunctionOutlined />}></Button>
                <Button icon={<DownloadOutlined />}>Скачать</Button>
                <Button icon={<UploadOutlined />}>Загрузить</Button>
            </Space>}}
        />
    </div>);
};

const DimsPane = () => {
    const initValues = {
        partNum: 2,
        parts: [
            {num: 1, rows: 5, cols: 5},
            {num: 2, rows: 15, cols: 15},
        ]
    };

    return (<div>
        <Form 
            initialValues={initValues}
            layout='vertical'
        >
            <Row gutter={32}>
                <Col>
                    <Form.Item label='Кол-во разделов' name='dim' layout='horizontal'>
                        <StyledInput 
                            addonBefore={<MinusOutlined onClick={()=>console.log('SUB')} />}
                            addonAfter={<PlusOutlined onClick={()=>console.log('ADD')} />}
                            style={{width:120,textAlign:'center',}}
                            variant="outlined"
                            name='partNum'
                            allowClear={false}
                            size='large'
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Alert showIcon type='warning' message='Внимание! При уменьшении кол-ва разделов, описания ранее созданных последних разделов могут будут удалены'></Alert>
                </Col>
            </Row>
            <Row gutter={32}>
                <Col>
                    <Form.List
                        name="parts"
                    >
                        {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={[10,10]} wrap={false}>
                                    <Col flex={'90px'}>
                                        {index === 0 ? <Typography.TitleH4 style={{marginTop: 0}}>Разделы</Typography.TitleH4> : null}
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label={index === 0 ? '#' : ''}
                                            name={[index, "num"]}
                                        >
                                            <InputNumber style={{ width: '30px' }} allowClear={false} disabled min={1} max={20} />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={'90px'}>
                                        <Form.Item
                                            label={index === 0 ? 'Строки' : ''}
                                            name={[index, "rows"]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col flex={'90px'}>
                                        <Form.Item
                                            label={index === 0 ? 'Графы' : ''}
                                            name={[index, "cols"]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col flex='auto' style={{alignSelf:'flex-end'}}>
                                        <Paragraph ellipsis={{ rows: 1, expandable: false, }} style={{marginBottom: 28}}>Очень длинное название раздела которое тут появится после того как его введут на странице управления разделом и еще раз Очень длинное название раздела которое тут появится после того как его введут на странице управления разделом</Paragraph>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                >
                    <PlusOutlined /> Add field
                </Button>
                </Form.Item>
                        </>
                        )}
                        
                    </Form.List>

                </Col>
                <Col span={24} style={{marginLeft:90}}>
                    <Form.Item label=' '>
                        <Button color='primary' variant='outlined'>Применить</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>);
};

const PartsPane = () => {
    
    return (<div>
       <StyledTabs 
            items={[
                {
                    key: '1',
                    label: 'Строки',
                    children: <RowsPane />,
                },
                {
                    key: '2',
                    label: 'Графы',
                    children: <ColsPane />,
                },
                {
                    key: '3',
                    label: 'CNV',
                    children: <CnvPane />,
                },
                {
                    key: '4',
                    label: 'Логический контроль',
                    children: <LogPane />,
                },
            ]}
            defaultActiveKey="1"
            renderTabBar={(props, DefaultTabBar) => (
                <StickyBox offsetTop={90} offsetBottom={0} style={{ zIndex: 2 }}>
                  <DefaultTabBar {...props} style={{ background: Colors.white }} />
                </StickyBox>
            )}
            tabBarExtraContent={{right: <Space>
                <Button type="text" icon={<DownloadOutlined />}></Button>
                <Button type="text" icon={<UploadOutlined />}></Button>
                <Button>Скачать шаблон</Button>
            </Space>}}
        />
    </div>);
};

const ExcelPane = () => {
    return <DemoTable />
};

const RowsPane = () => {
    return <Space size={10} direction='vertical' style={{width:'100%'}}>{range(1,20).map(i => <Row key={`row-${i}`} gutter={10}><Col flex='60px'><Input disabled value={i} /></Col><Col flex="auto"><Input /></Col></Row>)}</Space>;
};
const ColsPane = () => {
    return <Space size={10} direction='vertical' style={{width:'100%'}}>{range(1,20).map(i => <Row key={`col-${i}`} gutter={10}><Col><Input disabled value={i} /></Col><Col flex="auto"><Input /></Col></Row>)}</Space>;
};
const CnvPane = () => {
    return <TextArea autoSize={{ minRows: 20 }}></TextArea>;
};
const LogPane = () => {
    return <TextArea autoSize={{ minRows: 20 }}></TextArea>;
};










export default FormEdit;
// <Button variant={"outlined"}>Button from ui-kit</Button>

const ReportInfo:React.FC<{status?:boolean}> = ({status}) => {
    const [error, setError] = useState(status);
    return <div style={{padding:20}}>
        <Space direction='vertical' style={{color:Colors.cloudy.dark}} size={24}>
            <Space direction='vertical' style={{color:Colors.cloudy.dark}} size={4}>
                <div>Статус версии: <span style={{color:Colors.tag.green}}>Актуальная</span></div>
                <div>Дата, время последнего изменения формы: <span style={{color:Colors.black}}>02.02.2024 13:43:31</span></div>
                <div>ФИО редактора: <span style={{color:Colors.black}}>Петров А.В.</span></div>
                <div>Статус формы: <span style={{color:Colors.black}}>Готова к публикации</span></div>
            </Space>
            {error 
                ? <Alert
                        message="Не загружен шаблон/не проведена проверка"
                        type="error"
                        showIcon
                        icon={<InfoCircleOutlined />}
                    />
                : <Button block={false} type='primary' onClick={(e) => setError(prev => !prev)}>Опубликовать</Button>
            }
        </Space>
    </div>;
};