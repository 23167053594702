import {Link, Route, Routes} from "react-router-dom";
import App from "./App";
import CardEdit from "./pages/cartEdit";
import FormEdit from "./pages/formEdit";
import FilterPage from './pages/filter';
import Elements from './pages/elements';

export const Pages = () => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", gap: 15}}>
                <Link to={"/"}>Главная</Link>
                <Link to={"/formedit"}>Редактирование формы</Link>
                <Link to={"/cardedit"}>Редактирование карты</Link>
                <Link to={"/filters"}>Фильтры</Link>
                <Link to={"/elements"}>Элементы</Link>
            </div>
            <div>
                <Routes>
                    <Route path={'/cardedit'} element={<CardEdit />}/>
                    <Route path={'/formedit'} element={<FormEdit />}/>
                    <Route path={'/filters'} element={<FilterPage />}/>
                    <Route path={'/elements'} element={<Elements />}/>
                    <Route index element={<App/>}/>
                </Routes>
            </div>
        </div>
    );
};
